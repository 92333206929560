
import { Component, Vue } from 'vue-property-decorator'
import Er404 from '@/components/404/Er404.vue'

@Component({
  components: {
    Er404
  },
  metaInfo() {
    return{
    title: this.$tc('caption'),
  }}
})

export default class Page404 extends Vue {

}
